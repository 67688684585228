<template>
  <div class="outbox">
    <div class="a">
      <img class="one" src="../../assets/img/WAP新闻中心_02.jpg" alt="" />
    </div>
    <div class="content">
       <div class="b" @click="link(5)">
        <img class="one" src="../../assets/img/组11.png" alt="" />
      </div>
      <div class="b" @click="link(1)">
        <img class="one" src="../../assets/img/组1.png" alt="" />
      </div>
      <div class="b" @click="link(2)">
        <img class="one" src="../../assets/img/组1拷贝.png" alt="" />
      </div>
      <div class="b" @click="link(3)">
        <img class="one" src="../../assets/img/组1拷贝2.png" alt="" />
      </div>
      <div class="b" @click="link(4)">
        <img class="one" src="../../assets/img/组1拷贝3.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    link(id) {
      this.$router.push({
        path: `newsList`,
        query: {
          id: id,
        },
      });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 40px 22px;
  .b {
    height: 227px;
    margin-bottom: 30px;
  }
  .b:last-child {
    margin-bottom: 0px;
  }
}
.a {
  height: 149.5px;
}
.outbox {
  height: 100%;
  background: #ededed;
  width: 100%;
}
</style>